<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Copyright © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.simrush.com"
        target="_blank"
      >SimRush</b-link>.
      All rights reserved. <b-link to="/legal">Legal Notices</b-link>.
    </span>

    <span class="float-md-right d-none d-md-block">Version {{ version }}

    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BLink,
  },
  computed: {
    ...mapState('app', ['version']),
  },
}
</script>
