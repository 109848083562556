<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <legal-modal />
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" />

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--   // create   user-invites -->
      <router-link
        v-b-tooltip.hover.bottom
        to="/invite-user"
        class="invite-user-nav"
        title="Invite user"
      >
        <feather-icon
          v-if="$can('create', 'user-invites')"
          icon="UserPlusIcon"
          size="16"
          class="text-body align-middle"
          stroke="#ffe3e2"
        />
      </router-link>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >

        <template #button-content>
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.first_name + ' ' + userData.last_name)"
            size="30px"
            rounded
            class="user-avatar"
          />
          <div
            class="d-sm-flex d-none user-nav user-info-container ml-1"
          >
            <p
              v-if="userData.first_name"
              class="user-name font-weight-bolder mb-0 ml-0"
            >
              {{ userData.email }}
            </p>

            <span
              v-b-tooltip.hover.bottom
              class="user-status"
              :title="userRoleTooltip"
            >
              {{ userRole }}
            </span>
          </div>

        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          to="/profile"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$can('view', 'profile-company')"
          link-class="d-flex align-items-center"
          to="/company-profile"
        >
          <feather-icon
            size="16"
            icon="PackageIcon"
            class="mr-50"
          />
          <span>Company Profile</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click.prevent="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
  BNavItemDropdown, BDropdownItem, BAvatar, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { mapState } from 'vuex'
import { avatarText } from '@core/utils/filter'
import LegalModal from '@/views/apps/legal/LegalModal.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    LegalModal,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  computed: {
    ...mapState('account', ['userData']),
    userRole() {
      let role = ''
      let access = ''
      switch (this.userData.roles[0]) {
        case 'simrush':
          role = 'SR'
          break
        case 'master-distributor':
          role = 'MD'
          break
        case 'distributor':
          role = 'D'
          break
        case 'reseller':
          role = 'R'
          break
        case 'client':
          role = 'C'
          break
        default:
          role = ''
      }
      switch (this.userData.roles[1]) {
        case 'primary-admin':
          access = '(PA)'
          break
        case 'read-only':
          access = '(RO)'
          break
        case 'admin':
          access = '(A)'
          break
        case 'editor':
          access = '(E)'
          break
        default:
          access = ''
      }
      return `${role} ${access}`
    },
    userRoleTooltip() {
      let group = this.capitalizeFirstLetter(this.userData?.roles[0]?.replaceAll('-', ' ') ?? '')
      const role = this.capitalizeFirstLetter(this.userData?.roles[1]?.replaceAll('-', ' ') ?? '')

      if (group === 'Simrush') {
        group = 'SimRush'
      }

      return `${group} (${role})`
    },
  },
  methods: {
    async logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      try {
        await this.$http.post('/auth/logout')
      } finally {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
}
</script>
<style lang="scss">
.invite-user{
  .dropdown-item{
    padding: 0 !important;
  }
  .nav-link{
    padding: 0.65rem 1.28rem !important;
  }
}
</style>
