<template>
  <b-modal
    id="legalExpiredModal"
    ref="legalExpiredModal"
    title="New EULA Update "
    size="lg"
    hide-footer
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    @hide="closeModal"
  >
    <div>
      <div class="legal-modal-body">
        <b-row>
          <b-col md="12">
            Our End User License Agreement (EULA) has been updated. <b>Please review and accept the new terms to continue using the site.</b>
          </b-col>
          <b-col md="12">
            <div class="modal-legal-list">
              <h3>Latest EULA Versions:</h3>
              <ul>
                <li><b-link
                  :href="lastURL"
                  target="_blank"
                >
                  Legal/EULA/{{ formattedName }}
                </b-link></li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="legal-modal-footer">
        <div class="legal-modal-check-container">
          <b-form-checkbox
            id="accept-terms"
            v-model="isAccepted"
            name="is_hidden"
            inline
            class="mr-25"
          />
          <label for="accept-terms">I accept updated End User License Agreement</label>
        </div>
        <b-button
          variant="primary"
          :disabled="!isAccepted"
          @click="handleAccept"
        >
          Accept
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import {
  BRow, BCol, BModal, BFormCheckbox, BLink, BButton,
} from 'bootstrap-vue'
import { required, max } from '@validations'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import axios from '../axios-resolver'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormCheckbox,
    BLink,
    BButton,
  },
  data() {
    return {
      required,
      max,
      isAccepted: false,
      router: this.$router,
      newEulaInfo: null,

      lastURL: null,
      formattedName: null,
    }
  },
  watch: {
    $route() {
      const dom = document.getElementById('legalExpiredModal')
      if (this.newEulaInfo && dom) {
        this.$refs.legalExpiredModal.show()
      } else if (!dom && this.newEulaInfo) {
        // reload
        this.$router.go()
      }
    },
    newEulaInfo(newInfo) {
      if (newInfo) {
        this.lastURL = newInfo.url
        this.formattedName = this.formatDate(newInfo.date)
      }
    },
  },
  created() {
    this.checkIsUpdatedEula()
  },
  methods: {
    async checkIsUpdatedEula() {
      await new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, 0)
      })
      const { data: { data } } = await axios.get('/eula/info')
      this.newEulaInfo = false
      if (data.length) {
        this.newEulaInfo = { ...data[data.length - 1] }
        this.$refs.legalExpiredModal.show()
      }
    },
    formatDate(date) {
      const formatDate = new Date(date)
      const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

      const month = formatDate.getMonth()
      const year = formatDate.getFullYear()

      return months[month] + year.toString().slice(-2)
    },
    closeModal() {
      this.form = {}

      this.$refs.legalExpiredModal.hide()
    },
    async handleAccept() {
      try {
        await axios.put('/eula/accepted')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        this.closeModal()
      } catch (error) {
        console.warn(error)
      }
    },
  },
}
</script>

<style lang="scss">
</style>
