<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div
      slot="breadcrumb"
      class="px-1"
    >
      <app-breadcrumb />
    </div>

    <!-- Footer -->
    <div slot="footer">
      <app-footer />
    </div>
    <!-- /Footer -->

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import Navbar from '../components/Navbar.vue'
import AppBreadcrumb from '../components/AppBreadcrumb.vue'
import AppFooter from '../components/AppFooter.vue'

export default {
  components: {
    AppBreadcrumb,
    LayoutVertical,
    Navbar,
    AppFooter,
  },
}
</script>
