import rolesPermission from '@/libs/acl_roles/rolesPermission'
import actionList from '@/libs/acl_roles/actionList'

const { ACTION_VIEW } = actionList
const { MANAGE_HARDWARE } = rolesPermission

export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'dashboard',
  },
  {
    title: 'Companies',
    route: 'company-list',
    resource: 'companies',
    action: ACTION_VIEW,
    icon: 'PackageIcon',
  },
  {
    title: 'Users',
    route: 'user-list',
    resource: 'users',
    action: ACTION_VIEW,
    icon: 'UsersIcon',
  },
  // {
  //   title: 'Users',
  //   icon: 'UsersIcon',
  //   children: [
  //     {
  //       title: 'SimRush',
  //       route: 'simrush-list',
  //       resource: 'simrush-users',
  //       action: ACTION_VIEW,
  //     },
  //     {
  //       title: 'Master distributors',
  //       route: 'master-distributor-list',
  //       resource: 'master-distributor-users',
  //       action: ACTION_VIEW,
  //     },
  //     {
  //       title: 'Distributors',
  //       route: 'distributor-list',
  //       resource: 'distributor-users',
  //       action: ACTION_VIEW,
  //     },
  //     {
  //       title: 'Resellers',
  //       route: 'reseller-list',
  //       resource: 'reseller-users',
  //       action: ACTION_VIEW,
  //     },
  //     {
  //       title: 'Clients',
  //       route: 'client-list',
  //       resource: 'client-users',
  //       action: ACTION_VIEW,
  //     },
  //   ],
  // },
  {
    title: 'Licences',
    route: 'licence-list',
    icon: 'FileTextIcon',
    action: 'view-index',
    resource: 'licences',
  },
  {
    title: 'Sites',
    icon: 'MapIcon',
    children: [
      {
        title: 'Sites Management',
        route: 'sites-list',
        resource: 'sites',
        action: ACTION_VIEW,
      },
      {
        title: 'VPN Dialup Users',
        route: 'dialups-list',
        resource: 'vpn-dialups',
        action: ACTION_VIEW,
      },
    ],
  },
  {
    title: 'Inventory',
    route: 'hardware-list',
    action: ACTION_VIEW,
    resource: 'hardware',
    icon: 'ListIcon',
  },
  {
    title: 'Finances',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'RADIUS Tariffs',
        route: 'tariffs-list',
        action: ACTION_VIEW,
        resource: 'tariffs',
      },
      {
        title: 'RADIUS Services',
        route: 'services-list',
        action: ACTION_VIEW,
        resource: 'services',
      },
    ],
  },
  {
    title: 'Radius',
    icon: 'RadioIcon',
    children: [
      {
        title: 'NAS',
        route: 'nas-list',
        action: ACTION_VIEW,
        resource: 'nas',
      },
      {
        title: 'IPAM',
        children: [
          {
            title: 'IPv4',
            route: 'ipam-list-ipv4',
            resource: 'subnets',
            action: ACTION_VIEW,
          },
          {
            title: 'IPv6',
            route: 'ipam-list-ipv6',
            resource: 'subnets',
            action: ACTION_VIEW,
          },
        ],
      },
    ],
  },
  {
    title: 'Database Admin',
    icon: 'LockIcon',
    children: [
      {
        title: 'Hardware',
        resource: MANAGE_HARDWARE,
        action: ACTION_VIEW,
        children: [
          {
            title: 'Models',
            resource: 'hardware-models',
            action: ACTION_VIEW,
            route: 'hardware-models-list',
          },
          {
            title: 'Types',
            route: 'hardware-types-list',
            resource: 'hardware-types',
            action: ACTION_VIEW,
          },
          {
            title: 'Manufacturers',
            route: 'hardware-manufacturer-list',
            resource: 'hardware-manufacturers',
            action: ACTION_VIEW,
          },
          {
            title: '.DPK files',
            route: 'hardware-dpk-files',
            resource: 'hardware-manufacturers',
            action: ACTION_VIEW,
          },
        ],
      },
      {
        title: 'Networks',
        resource: 'subnet-categories',
        action: ACTION_VIEW,
        children: [
          {
            title: 'Categories',
            route: 'ipam-categories',
            resource: 'subnet-categories',
            action: ACTION_VIEW,
          },
        ],
      },
    ],
  },
]
